import { useEffect, useState, useRef } from 'react'
import { useWeb3React } from 'hooks/useWeb3React'
import { Web3Provider } from '@ethersproject/providers'
import { simpleRpcProvider } from 'utils/providers'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { CHAIN_ID } from 'config'
import { AbstractConnector } from '@web3-react/abstract-connector'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */

export class Web3ReactContext implements Web3ReactContextInterface<Web3Provider> {
  connector?: AbstractConnector
  library?: any
  chainId?: number
  account?: string
  active: boolean
  error?: Error
  activate: (connector: AbstractConnector, onError?: (error: Error) => void, throwErrors?: boolean) => Promise<void>
  setError: (error: Error) => void
  deactivate: () => void
  isWrongChainId: boolean;
}

const useActiveWeb3React = (): Web3ReactContext => {
  const { library, chainId, ...web3React } = useWeb3React()
  const refEth = useRef(library)
  const [provider, setProvider] = useState(library || simpleRpcProvider)

  const isWrongChainId = chainId && chainId !== CHAIN_ID;

  useEffect(() => {
    if (library !== refEth.current) {
      setProvider(library || simpleRpcProvider)
      refEth.current = library
    }
  }, [library])

  return { isWrongChainId, library: provider, chainId: isWrongChainId ? CHAIN_ID : chainId || CHAIN_ID, ...web3React }
}

export default useActiveWeb3React
