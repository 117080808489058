import { GoogleAnalyticEvent } from './actionSendEvent'

export const GOOGLE_ANALYTIC_EVENTS = {
  JOIN_DISCORD: {
    action: '[click][button][join_discord]',
    category: 'home',
    label: 'button',
  } as GoogleAnalyticEvent,

  JOIN_TELE: {
    action: '[click][button][join_tele]',
    category: 'home',
    label: 'button',
  } as GoogleAnalyticEvent,

  ROUTE_SWAP: {
    action: '[click][button][route_to_swap]',
    category: 'home',
    label: 'button',
  } as GoogleAnalyticEvent,

  GO_TO_WHITEPAPER: {
    action: '[click][button][go_to_whitepaper]',
    category: 'home',
    label: 'button',
  } as GoogleAnalyticEvent,

  HOW_TO_BRIDGE: {
    action: '[click][button][how_to_bridge]',
    category: 'home',
    label: 'button',
  } as GoogleAnalyticEvent,

  CONNECT_WALLET: {
    action: '[click][button][connect_wallet]',
    category: 'click',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_SWAP: {
    action: '[click][button][button_swap]',
    category: 'swap',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_CLAIM_REWERD: {
    action: '[click][button][button_claim_reward]',
    category: 'swap',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_SELECT_TOKEN_SWAP: {
    action: '[click][button][button_select_token_swap]',
    category: 'swap',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_ADD_LIQUIDITY: {
    action: '[click][button][button_add_liquidity]',
    category: 'liquidity',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_APPROVE_TOKEN: {
    action: '[click][button][button_approve_token]',
    category: 'click',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_STAKE_LP: {
    action: '[click][button][button_stake_lp]',
    category: 'farm',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_HARVEST_FARM: {
    action: '[click][button][button_harvest_farm]',
    category: 'farm',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_LOCKING: {
    action: '[click][button][button_locking]',
    category: 'locking',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_HARVEST_LOCKING: {
    action: '[click][button][button_harvest_locking]',
    category: 'locking',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_END_LOCKING: {
    action: '[click][button][button_end_locking]',
    category: 'locking',
    label: 'button',
  } as GoogleAnalyticEvent,

  BUTTON_BRIDGE_TOKEN: {
    action: '[click][button][button_bridge_token]',
    category: 'bridge',
    label: 'button',
  } as GoogleAnalyticEvent,
}
