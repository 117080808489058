import { ChainId } from '@hunnyswap/sdk'
import { VaultKey } from 'state/types'
import getTokenLogoURL from 'utils/getTokenLogoURL'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const vaultPoolConfig = {
  [VaultKey.CakeVault]: {
    name: 'Auto XOXO',
    description: 'Automatic restaking',
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      // primarySrc: `/images/tokens/${tokens.cake.address}.svg`,
      primarySrc: getTokenLogoURL(tokens.cake.address),

      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IfoPool]: {
    name: 'IFO XOXO',
    description: 'Stake XOXO to participate in IFOs',
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      // primarySrc: `/images/tokens/${tokens.cake.address}.svg`,
      primarySrc: getTokenLogoURL(tokens.cake.address),
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      [ChainId.TESTNET]: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      [ChainId.MAINNET]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  }
]

export default pools
