import BigNumber from 'bignumber.js'
import { NetworkEnum } from './networks'

export enum TypeToken {
  NATIVE = 'NATIVE',
  STABLE = 'STABLE',
  NORMAL = 'NORMAL',
}

export type NetworkTypes = {
  display: string
  name: string
  code: string
  native: string
  icon: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  network: {
    chainId: number
    rpcCollections?: string[]
    blockExplorerUrls?: string[]
    displayName?: string
  }
}

export type CurrencyToken = {
  name: string
  symbol: string
  code: string
  icon: string
  decimals: number
  type: TypeToken
  networks: {
    [nameNetwork: number]: {
      name?: string
      symbol?: string
      code?: string
      icon?: string
      decimals?: number
      address: string
      type?: TypeToken
    }
  }
  options?: {
    disableFrom?: NetworkEnum[]
  }
  balance?: BigNumber
  allowance?: BigNumber
}
