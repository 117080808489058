import { createReducer } from '@reduxjs/toolkit'
import { updateApr, resetMyLocking, updateMyLocking, updateMyLockingRewards, updateXoxoPerShare } from './actions'

export interface LockState {
  readonly xoxoPerShare: number;

  readonly aprByTerm: {
    periodIndex: number,
    estApr: string
  }[]

  readonly myLocking: {
    balance: string;
    pendingReward: string;
    totalRewards: string;
    lockPeriodIndex: number;
    lockUtil: number;
    vesting: number;
  }
}

const initialState: LockState = {
  xoxoPerShare: null,
  aprByTerm: [],
  myLocking: {
    lockPeriodIndex: null,
    balance: '0',
    pendingReward: '0',
    totalRewards: '0',
    lockUtil: null,
    vesting: 0
  }
}

export default createReducer<LockState>(initialState, (builder) =>
  builder
    .addCase(resetMyLocking, (state) => {
      return {
        ...state,
        myLocking: initialState.myLocking
      }
    })
    .addCase(updateApr, (state, { payload: { aprByTerm } }) => {
      return {
        ...state,
        aprByTerm,
      }
    })
    .addCase(updateMyLocking, (state, { payload: { myLocking } }) => {
      return {
        ...state,
        myLocking,
      }
    })
    .addCase(updateXoxoPerShare, (state, { payload: { xoxoPerShare } }) => {
      return {
        ...state,
        xoxoPerShare,
      }
    })
    .addCase(updateMyLockingRewards, (state, { payload: { pendingReward } }) => {
      return {
        ...state,
        myLocking: {
          ...state.myLocking,
          pendingReward
        },
      }
    })
)
