import BigNumber from 'bignumber.js'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

export const ethersToSerializedBigNumber = (ethersBn: EthersBigNumber): SerializedBigNumber =>
  ethersToBigNumber(ethersBn).toJSON()

export const ethersToBigNumber = (ethersBn: EthersBigNumber): BigNumber => new BigNumber(ethersBn.toString())

/**
 * Convert 10999000 to 10.999
 */
export const toTokenUnitsBN = (tokenAmount: string | number | BigNumber, tokenDecimals: number): BigNumber => {
  const amt = new BigNumber(tokenAmount)
  const digits = new BigNumber(10).pow(new BigNumber(tokenDecimals))
  return amt.div(digits)
}

export function delineate(bnStr: string, decimalDigitTake?: number) {
  try {
    const parts = bnStr.split('.') || []
    if (decimalDigitTake && parts[1]) {
      parts[1] = parts[1].substr(0, decimalDigitTake)
    }

    const formatDecimal = parts[1] && Number(parts[1]) && decimalDigitTake !== 0 ? `.${parts[1]}` : ''
    return parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + formatDecimal
  } catch (err) {
    return bnStr
  }
}

export const parseNumberDisplay = (value: BigNumber, decimals = 18, fixed = 10) => {
  const parsedValue = toTokenUnitsBN(value, decimals).toString(10)
  return delineate(
    Number(parsedValue) ? parsedValue : toTokenUnitsBN(value, decimals).toString(10),
    Number(parsedValue) ? fixed : 10,
  )
}

/**
 * Convert 10.999 to 10999000
 */
 export function toBaseUnitBN(
  rawAmt: string | number | BigNumber,
  decimals: number
): BigNumber {
  const raw = new BigNumber(rawAmt);
  const base = new BigNumber(10);
  const decimalsBN = new BigNumber(decimals);
  return raw.times(base.pow(decimalsBN)).integerValue();
}
