import { useTooltip, WarningIcon } from '@hunnyswap/uikit'
import React from 'react'
import styled from 'styled-components'
import { NetworkTypes } from 'views/Bridge/types'

const StyledImg = styled.img`
  width: 100%;
  border-radius: 50%;
`

const StyledWarning = styled.div`
  border-radius: 50%;
  border: 2px solid #1bc9d4;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 12px;
`

const NetworkMenu: React.FC<{ infoChain: NetworkTypes }> = ({ infoChain }) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(infoChain ? infoChain.display : 'Unknow Chain', {
    placement: 'bottom',
  })
  return (
    <StyledWarning ref={targetRef}>
      {infoChain ? <StyledImg src={infoChain?.icon} alt="network" /> : <WarningIcon color="failure" width="24px" />}
      {tooltipVisible && tooltip}
    </StyledWarning>
  )
}

export default NetworkMenu
