import axios from 'axios'
import lodash from 'lodash'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { API_INFO_BRIDGEV2, API_INFO_BRIDGEV3 } from 'config/constants/endpoints'
import { TypeToken } from 'views/Bridge/types'
import { tokenNeedToAddOrUpdate } from 'views/Bridge/tokens'
import { BridgeInfo, ChainAddressBridgeInfo } from '../types'

const initialState: BridgeInfo = { infoBridge: { native: {}, stablev3: {}, underlyingv2: {} } }

export const fetchApiInfoBridge = () => async (dispatch) => {
  const datav3 = await axios({
    method: 'get',
    url: API_INFO_BRIDGEV3,
  })
    .then((res) => res.data)
    .catch((err) => {
      console.error('Fetch API Bridge: ', err)
      return null
    })
  const datav2 = await axios({
    method: 'get',
    url: API_INFO_BRIDGEV2,
  })
    .then((res) => res.data)
    .catch((err) => {
      console.error('Fetch API Bridge: ', err)
      return null
    })

  dispatch(
    setInfoBridge({
      v3: datav3,
      v2: datav2,
    }),
  )

  return {
    v3: datav3,
    v2: datav2,
  }
}

const parseV2toV3 = (_data: any): ChainAddressBridgeInfo | null => {
  try {
    if (!_data) return null
    return {
      address: _data.DestToken.ContractAddress?.toLowerCase() || '',
      MaximumSwap: _data.DestToken.MaximumSwap,
      MinimumSwap: _data.DestToken.MinimumSwap,
      BigValueThreshold: _data.DestToken.BigValueThreshold,
      SwapFeeRatePerMillion: _data.DestToken.SwapFeeRate,
      MaximumSwapFee: _data.DestToken.MaximumSwapFee,
      MinimumSwapFee: _data.DestToken.MinimumSwapFee,
      anyToken: {
        name: _data.name || '',
        address: _data.DestToken.ContractAddress?.toLowerCase() || '',
        symbol: _data.symbol || '',
        decimals: _data.SrcToken.Decimals || '',
      },
      options: {
        router: _data.DestToken.DcrmAddress,
        routerABI: _data.DestToken.routerABI,
      },
    } as unknown as ChainAddressBridgeInfo
  } catch (err) {
    return null
  }
}

const updateValueForFieldType = ({ field, infoToken, from, to }) => {
  const _field = { ...field } || {}
  const parseData = parseV2toV3(infoToken)
  const chainIdFrom = from.chainId || 0
  const chainIdTo = to.chainId || 0
  const addressFrom = from.address.toLowerCase() || ''
  const destChains = lodash.get(_field, `[${chainIdFrom}][${addressFrom}].destChains`, {})

  destChains[chainIdTo] = {
    ...destChains[chainIdTo],
    ...parseData,
  }

  if (Object.keys(from?.variables || {}).length > 0) {
    destChains[chainIdTo] = {
      ...destChains[chainIdTo],
      ...from.variables,
    }
  }

  const data = {
    address: parseData.address,
    chainId: infoToken?.srcChainID,
    destChains,
  }
  lodash.set(_field, `[${chainIdFrom}][${addressFrom}]`, data)

  return _field
}

export const bridge = createSlice({
  name: 'Bridge',
  initialState,
  reducers: {
    setInfoBridge: (state, action: PayloadAction<any>) => {
      const native = action?.payload.v3?.NATIVE || {}
      const stablev3 = action?.payload.v3?.STABLEV3 || {}
      const underlyingv2 = action?.payload.v3?.UNDERLYINGV2 || {}
      try {
        let _native = { ...native } || {}
        let _stablev3 = { ...stablev3 } || {}
        let _underlyingv2 = { ...underlyingv2 }

        tokenNeedToAddOrUpdate.forEach((item) => {
          const infoToken = lodash.get(action, `payload.v2[${item.from.chainId}][${item.keyApi}]`, {})
          const isNative = item.from.type === TypeToken.NATIVE
          const isStable = item.from.type === TypeToken.STABLE
          const isNormal = item.from.type === TypeToken.NORMAL
          const dataForUpdate = {
            infoToken,
            from: item.from,
            to: item.to,
          }
          const dataForUpdateReserse = {
            infoToken,
            from: item.to,
            to: item.from,
          }
          if (isNative) {
            _native = updateValueForFieldType({
              field: _native,
              ...dataForUpdate,
            })
            _native = updateValueForFieldType({
              field: _native,
              ...dataForUpdateReserse,
            })
          } else if (isStable) {
            _stablev3 = updateValueForFieldType({
              field: _stablev3,
              ...dataForUpdate,
            })
            _stablev3 = updateValueForFieldType({
              field: _stablev3,
              ...dataForUpdateReserse,
            })
          } else if (isNormal) {
            _underlyingv2 = updateValueForFieldType({
              field: _underlyingv2,
              ...dataForUpdate,
            })
            _underlyingv2 = updateValueForFieldType({
              field: _underlyingv2,
              ...dataForUpdateReserse,
            })
          }
        })

        state.infoBridge = { native: _native, stablev3: _stablev3, underlyingv2: _underlyingv2 }
        return state
      } catch (err) {
        state.infoBridge = { native, stablev3, underlyingv2 }
        return state
      }
    },
  },
})

// Actions
export const { setInfoBridge } = bridge.actions

export default bridge.reducer
