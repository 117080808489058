import { createReducer } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { updateData, updateLoading, resetRewardsState } from './actions'

export interface RewardState {
  readonly rewardsBalance: string
  readonly claimPercent: number
  readonly isLoading: boolean
}

const initialState: RewardState = {
  rewardsBalance: '0',
  claimPercent: 0.02,
  isLoading: false,
}

export default createReducer<RewardState>(initialState, (builder) =>
  builder
    .addCase(resetRewardsState, () => initialState)
    .addCase(updateLoading, (state, { payload: { isLoading } }) => {
      return {
        ...state,
        isLoading,
      }
    })
    .addCase(updateData, (state, { payload: { rewardsBalance, claimPercent } }) => {
      return {
        ...state,
        rewardsBalance: rewardsBalance,
        claimPercent,
      }
    })
)
