import { Flex } from '@hunnyswap/uikit'
import React, { useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import router from 'next/router'

interface Props {
  isDark: boolean
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`

const StyledFlex = styled(Flex)`
  position: relative;
  width: 36px;
  ${({ theme }) => theme.mediaQueries.nav} {
    width: 200px;
  }
`

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  bottom: 16px;

  .mobile-icon {
    width: 36px;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: none;
    }
  }
  .desktop-icon {
    width: 200px;
    display: none;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
  }
  .eye {
    animation-delay: 20ms;
  }
  &:hover {
    .eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`

const Logo: React.FC<Props> = () => {
  const href = '/'
  const isAbsoluteUrl = href.startsWith('http')
  const innerLogo = (
    <>
      <img src="/logo.png" className="mobile-icon" alt="logo mobile" />
      <img src="/logo-with-text.png" className="desktop-icon" alt="logo web" />
    </>
  )

  const routePage = useCallback(() => {
    router.push('/')
  }, [])

  return (
    <StyledFlex>
      {isAbsoluteUrl ? (
        <StyledLink as="a" onClick={routePage} aria-label="Hunnyswap home page">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink onClick={routePage} aria-label="Hunnyswap home page">
          {innerLogo}
        </StyledLink>
      )}
    </StyledFlex>
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)
