import ImageTokens from 'assets/all.json'

enum AssetsTokenFrom {
  trustwallet = 'trustwallet',
}
const getTokenLogoURL = (address: string, options?: { from: AssetsTokenFrom.trustwallet | string }) => {
  const urlTrustWallet = `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`

  try {
    const tokens = ImageTokens?.tokens || []
    const findToken = tokens.find((item) => item?.address?.toString()?.toUpperCase() === address?.toUpperCase())
    if (findToken?.logoURI) {
      return findToken?.logoURI
    }

    let url = ''
    const from = options?.from || AssetsTokenFrom.trustwallet
    switch (from) {
      case AssetsTokenFrom.trustwallet:
        url = urlTrustWallet
        break
      default:
        break
    }
    return url
  } catch (err) {
    return urlTrustWallet
  }
}

export default getTokenLogoURL
