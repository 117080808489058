import { FooterLinkType } from '@hunnyswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t(''),
    items: [
      {
        label: t('CoinGecko (Soon)'),
        href: '',
      },
      {
        label: t('CoinMarketcap (Soon)'),
        href: '',
      },
    ],
  },
  {
    label: t(''),
    items: [
      {
        label: t('Docs'),
        href: 'https://docs.hunnyswap.com',
      },
      {
        label: t('Contact Support'),
        href: 'https://t.me/HunnyFinance',
      },
    ],
  },
  {
    label: t(''),
    items: [
      {
        label: 'Apply For Farm Or Listing',
        href: 'https://forms.gle/PxzNZBxVvPRhC6td8',
      },
      {
        label: t('Bug Bounty'),
        href: 'https://forms.gle/PxzNZBxVvPRhC6td8',
      },
    ],
  },
]
