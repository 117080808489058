import { createAction } from '@reduxjs/toolkit'

export const updateApr = createAction<{
  aprByTerm: {
    periodIndex: number,
    estApr: string
  }[]
}>('lock/updateApr')

export const updateMyLocking = createAction<{
  myLocking: {
    balance: string;
    pendingReward: string;
    lockPeriodIndex: number;
    totalRewards: string;
    vesting: number;
    lockUtil: number;
  }
}>('lock/updateMyLocking')

export const updateMyLockingRewards = createAction<{

  pendingReward: string;

}>('lock/updateMyLockingRewards')

export const resetMyLocking = createAction<{}>('lock/resetMyLocking')

export const updateXoxoPerShare = createAction<{
  xoxoPerShare: number;
}>('lock/updateXoxoPerShare')
