import React from 'react'
import { Button, useWalletModal } from '@hunnyswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from 'hooks/useWeb3React'
import { setupNetwork } from 'utils/wallet'
import { sendEvent } from 'utils/google-analytics/actionSendEvent'
import { GOOGLE_ANALYTIC_EVENTS } from 'utils/google-analytics/event'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  const { isWrongChainId } = useWeb3React()

  const handleSwitchNetwork = async (): Promise<void> => {
    await setupNetwork({})
  }

  if (isWrongChainId) {
    return (
      <Button onClick={handleSwitchNetwork} {...props} style={{ fontSize: 14 }}>
        {t('Switch to Avalanche to start!')}
      </Button>
    )
  }

  return (
    <Button onClick={() => {
      sendEvent(GOOGLE_ANALYTIC_EVENTS.CONNECT_WALLET);
      onPresentConnectModal()
    }} {...props}>
      {t('Connect Wallet')}
    </Button>
  )
}

export default ConnectWalletButton
