import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

import { ANYSWAP_V4_ROUTER_INTERFACE, ERC20_ABI } from 'config/abi/erc20'

import { getNetworkByChainId, ListNetwork } from 'views/Bridge/networks'
import { CurrencyToken, NetworkTypes } from 'views/Bridge/types'
import { UINT256_MAX } from 'config'
import { toBaseUnitBN } from 'utils/bigNumber'

// Private
const getTokenContract = (
  router: any,
  chainId: number,
  signer?: ethers.Signer | ethers.providers.Provider,
): ethers.Contract => {
  const simpleRpcProvider = signer ?? getRpcProviderByNetwork(chainId)
  if (simpleRpcProvider && router) {
    return new ethers.Contract(router, ERC20_ABI, simpleRpcProvider)
  }

  return null
}

const getTokenContractAnySwapRouter = ({
  router,
  chainId,
  signer,
}: {
  router: string
  chainId: number
  signer?: ethers.Signer | ethers.providers.Provider
}): ethers.Contract => {
  try {
    const simpleRpcProvider = signer ?? getRpcProviderByNetwork(chainId)
    if (simpleRpcProvider && router) {
      return new ethers.Contract(router, ANYSWAP_V4_ROUTER_INTERFACE, simpleRpcProvider)
    }
    return null
  } catch (err) {
    return null
  }
}

// Public
export const getNodeUrlByChainId = (chainId: number): string => {
  const network = getNetworkByChainId(chainId)
  const chainInfo = ListNetwork[network.code] as NetworkTypes
  if (chainInfo?.network) {
    const { rpcCollections } = chainInfo.network
    const randomIndex = Math.floor(Math.random() * 3)
    return rpcCollections[randomIndex] ? rpcCollections[randomIndex] : rpcCollections[0]
  }
  return ''
}

export const getRpcProviderByNetwork = (chainId: number) => {
  try {
    const rpcProvider = new ethers.providers.JsonRpcProvider(getNodeUrlByChainId(chainId))
    return rpcProvider
  } catch (err) {
    return null
  }
}

export const getBalance = async (address: string, chainId: number) => {
  try {
    const simpleRpcProvider = getRpcProviderByNetwork(chainId)
    if (simpleRpcProvider) {
      const nativeTokenBalance = await simpleRpcProvider.getBalance(address)
      return new BigNumber(nativeTokenBalance.toString())
    }
    return new BigNumber(0)
  } catch (err) {
    console.error(err)
    return new BigNumber(0)
  }
}

export const getFeeGas = async (chainId: number, balance: BigNumber, uint = 4) => {
  try {
    const simpleRpcProvider = getRpcProviderByNetwork(chainId)
    const feeGas = await simpleRpcProvider.estimateGas({})
    return new BigNumber(balance.minus(toBaseUnitBN(feeGas.mul(uint).toString(), 10).toNumber()))
  } catch (err) {
    console.error(err)
    return new BigNumber(balance)
  }
}
export const getTokenAllowance = async ({
  currency,
  account,
  chainId,
  spender,
}: {
  currency: CurrencyToken
  account: string
  chainId: number
  spender: string
}) => {
  const resAllowance = {
    ...currency,
    allowance: new BigNumber(0),
  }
  try {
    const network = getNetworkByChainId(chainId)
    const contract = getTokenContractAnySwapRouter({
      router: currency.networks[network?.code].address,
      chainId,
    })
    if (!contract) return resAllowance

    const response = await contract.allowance(account, spender)

    resAllowance.allowance = new BigNumber(response.toString())
    return resAllowance
  } catch (error) {
    return resAllowance
  }
}

export const getTokenBalance = async ({
  currency,
  account,
  chainId,
}: {
  currency: CurrencyToken
  account: string
  chainId: number
}) => {
  const resBalance = {
    ...currency,
    balance: new BigNumber(0),
  }

  try {
    const network = getNetworkByChainId(chainId)
    if (currency?.code === network?.native) {
      const nativeTokenBalance = await getBalance(account, chainId)
      resBalance.balance = nativeTokenBalance || new BigNumber(0)
      return resBalance
    }

    const contract = getTokenContract(currency.networks[chainId].address, chainId)

    if (!contract) return resBalance

    const response = await contract.balanceOf(account)

    const balance = new BigNumber(response.toString())

    resBalance.balance = balance || new BigNumber(0)
    return resBalance
  } catch (error) {
    console.error(error)
    return resBalance
  }
}

export const getTokenAllowanceBalance = async ({
  currency,
  account,
  chainId,
  spender,
}: {
  currency: CurrencyToken
  account: string
  chainId: number
  spender: string
}) => {
  const res = {
    ...currency,
    balance: new BigNumber(0),
    allowance: new BigNumber(0),
  }
  try {
    if (!account) return res
    const network = getNetworkByChainId(chainId)
    if (currency?.code === network?.native) {
      const nativeTokenBalance = await getBalance(account, chainId)

      res.balance = nativeTokenBalance || new BigNumber(0)
      res.allowance = new BigNumber(1)
      return res
    }

    const contract = await getTokenContract(currency.networks[chainId].address, chainId)

    if (!contract) return res

    const allowance = await contract.allowance(account, spender)
    res.allowance = new BigNumber(allowance.toString())

    const balance = await contract.balanceOf(account)
    res.balance = new BigNumber(balance.toString())

    return res
  } catch (error) {
    return res
  }
}

export const approveToken = async ({
  signer,
  currency,
  chainId,
  spender,
}: {
  signer?: ethers.Signer | ethers.providers.Provider
  currency: CurrencyToken
  chainId: number
  spender: string
}) => {
  try {
    const network = getNetworkByChainId(chainId)
    if (!network) return null
    const contract = getTokenContract(currency.networks[network?.code].address, chainId, signer)
    if (!contract) return null
    const tx = await contract.approve(spender, UINT256_MAX)
    const receipt = await tx.wait()

    return {
      tx,
      receipt,
    }
  } catch (err) {
    return null
  }
}

export const bridgeSwap = async ({
  infoBridgeToken,
  chainIdTo,
  price,
  account,
  signer,
}: {
  infoBridgeToken: any
  chainIdTo: number
  price: number | string
  account: string
  signer?: ethers.Signer | ethers.providers.Provider
}) => {
  try {
    const { infoBridge, routerABI: routerABIMain, router: routerMain } = infoBridgeToken
    const routerABI = routerABIMain || infoBridge?.options?.routerABI
    const router = routerMain || infoBridge?.options?.router

    const contract = await getTokenContractAnySwapRouter({
      chainId: infoBridgeToken.chainId,
      router,
      signer,
    })

    let tx = null
    let receipt = null
    const parsePrice = toBaseUnitBN(price, infoBridge.anyToken.decimals).toString(10)

    if (routerABI.match(/anySwapOutNative/)) {
      tx = await contract.anySwapOutNative(infoBridgeToken?.anyToken?.address, account, chainIdTo, {
        value: parsePrice,
      })
      receipt = await tx.wait()
    } else if (routerABI.match(/anySwapOutUnderlying/)) {
      tx = await contract.anySwapOutUnderlying(infoBridgeToken?.anyToken?.address, account, parsePrice, chainIdTo)
      receipt = await tx.wait()
    } else if (routerABI.match(/Swapout/)) {
      tx = await contract.Swapout(parsePrice, account)
      receipt = await tx.wait()
    } else if (routerABI.match(/anySwapOut/)) {
      tx = await contract.anySwapOut(infoBridgeToken?.anyToken?.address, account, parsePrice, chainIdTo)
      receipt = await tx.wait()
    }

    return {
      tx,
      receipt,
    }
  } catch (err) {
    console.error('err: ', err)
    return null
  }
}
