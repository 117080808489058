import { ChainId } from '@hunnyswap/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()
const farms: SerializedFarmConfig[] = [
  {
    pid: 7,
    lpSymbol: 'XOXO-AVAX LP',
    lpAddresses: {
      [ChainId.TESTNET]: '',
      [ChainId.MAINNET]: '0x5C2FcD829f2F65B95147A2E65fB05C75dAC95c0f',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'USDC-AVAX LP',
    lpAddresses: {
      [ChainId.TESTNET]: '',
      [ChainId.MAINNET]: '0xB02972A9Fd0F9d3FddD6e8885fDea380FF304473',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'USDT-AVAX LP',
    lpAddresses: {
      [ChainId.TESTNET]: '',
      [ChainId.MAINNET]: '0x86F721DB310bE07F45faFF6F9162421BE88548D1',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 8,
    lpSymbol: 'WETH.e-AVAX LP',
    lpAddresses: {
      [ChainId.TESTNET]: '',
      [ChainId.MAINNET]: '0x71c2f300076e7BCfcdc8238a5BE0FFD07Ef99Ea5',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'BNB-AVAX LP',
    lpAddresses: {
      [ChainId.TESTNET]: '',
      [ChainId.MAINNET]: '0x4AB2D6D8F8f8E92215214F149295fF7b3728A015',
    },
    token: serializedTokens.bnb,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 6,
    lpSymbol: 'USDT.e-AVAX LP',
    lpAddresses: {
      [ChainId.TESTNET]: '',
      [ChainId.MAINNET]: '0x53bebbAF1f98Aafd787d9bbdA1a7713f46DF1F8b',
    },
    token: serializedTokens.usdtE,
    quoteToken: serializedTokens.wbnb,
  },
]

export default farms
