import { Token } from '@hunnyswap/sdk'
import { SerializedToken } from 'config/constants/types'
import { parseUnits } from '@ethersproject/units'

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
    projectLink: token.projectLink,
    tokenInfo: token.tokenInfo,
  }
}

export function deserializeToken(serializedToken: SerializedToken): Token {
  return new Token(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name,
    serializedToken.projectLink,
    serializedToken.tokenInfo,
  )
}

export enum GAS_PRICE {
  default = 'default',
  fast = 'fast',
  instant = 'instant'
}
