// Set of helper functions to facilitate wallet setup

import { BASE_BSC_SCAN_URL, BASE_URL } from 'config'
import { nodes } from './getRpcUrl'
import getTokenLogoURL from './getTokenLogoURL'

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */

type SetupNetworkType = {
  switchChain?: boolean
  chainId?: string | number
  chainName?: string
  nativeCurrency?: {
    name?: string
    symbol?: string
    decimals?: number
  }
  rpcUrls?: string[]
  blockExplorerUrls?: string[]
}

export const setupNetwork = async ({
  chainId = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID, 10) || 0,
  chainName = 'Avalanche C-Chain',
  nativeCurrency = { name: 'AVAX', symbol: 'AVAX', decimals: 18 },
  rpcUrls = nodes,
  blockExplorerUrls = [`${BASE_BSC_SCAN_URL}/`],
  switchChain = false,
}: SetupNetworkType) => {
  const provider = window.ethereum
  if (provider) {
    try {
      if (switchChain) {
        return await provider
          .request({
            method: 'wallet_switchEthereumChain',
            id: 1,
            jsonrpc: '2.0',
            params: [
              {
                chainId: `0x${chainId?.toString(16) || 0}`,
              },
            ],
          })
          .then(() => {
            return true
          })
          .catch(() => {
            return false
          })
      }
      return await provider
        .request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainId?.toString(16) || 0}`,
              chainName,
              nativeCurrency: {
                name: nativeCurrency.name || 'AVAX',
                symbol: nativeCurrency.symbol || 'avax',
                decimals: nativeCurrency.decimals || 18,
              },
              rpcUrls,
              blockExplorerUrls,
            },
          ],
        })
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the Avalanche network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        // image: `${BASE_URL}/images/tokens/${tokenAddress}.png`,
        image: getTokenLogoURL(tokenAddress),
      },
    },
  })

  return tokenAdded
}
