import { useWeb3React as useWeb3ReactLib } from '@web3-react/core'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { CHAIN_ID } from 'config'
import { AbstractConnector } from '@web3-react/abstract-connector'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */

class Web3ReactContext implements Web3ReactContextInterface<any> {
  connector?: AbstractConnector
  library?: any
  chainId?: number
  account?: string
  active: boolean
  error?: Error
  activate: (connector: AbstractConnector, onError?: (error: Error) => void, throwErrors?: boolean) => Promise<void>
  setError: (error: Error) => void
  deactivate: () => void
  isWrongChainId: boolean;
}

export function useWeb3React(): Web3ReactContext {
  const { chainId, account, ...web3React } = useWeb3ReactLib()
  const isWrongChainId = chainId && chainId !== CHAIN_ID;


  return { isWrongChainId, chainId, account: isWrongChainId ? null : account, ...web3React }
  // return { isWrongChainId, chainId: isWrongChainId ? CHAIN_ID : chainId || CHAIN_ID, account: isWrongChainId ? null : account, ...web3React }
}
