export interface GoogleAnalyticEvent {
  category: string
  action: string
  label?: string
}

export function sendEvent(payload: GoogleAnalyticEvent, value?: string): void {
  try {
    let { action } = payload

    if (value) {
      action = `${payload.action}[${value}]`
    }

    (window as any).gtag('event', action, {
      event_category: payload.category,
      event_label: payload.label,
    })
  } catch (err) {
    console.error(err);
  }
}
