import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'HunnySwap',
  description:
    'The most popular AMM on Avalanche by user count! Earn XOXO through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by HunnySwap), NFTs, and more, on a platform you can trust.',
  image: 'https://hunnyswap.com/img-preview.jpg',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('HunnySwap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('HunnySwap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('HunnySwap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('HunnySwap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('HunnySwap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('HunnySwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('HunnySwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('HunnySwap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('HunnySwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('HunnySwap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('HunnySwap')}`,
      }
    case '/lock':
      return {
        title: `${t('Lock')} | ${t('HunnySwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('HunnySwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('HunnySwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('HunnySwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('HunnySwap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('HunnySwap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('HunnySwap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('HunnySwap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('HunnySwap Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('HunnySwap Info & Analytics')}`,
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('HunnySwap Info & Analytics')}`,
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('HunnySwap')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('HunnySwap')}`,
      }
    case '/nfts/activity':
      return {
        title: `${t('Activity')} | ${t('HunnySwap')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Profile')} | ${t('HunnySwap')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('HunnySwap')}`,
      }
    default:
      return null
  }
}
