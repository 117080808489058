import { NetworkTypes } from './types'

const path = 'images/network'

type ListNetworkType = {
  [key: string]: NetworkTypes
}

export enum NetworkEnum {
  BSC = 56,
  AVAX = 43114,
  FTM = 250,
  ETH = 1,
  AURORA = 1313161554,
}

export const ListNetwork: ListNetworkType = {
  ETH: {
    display: 'Ethereum',
    name: 'Ethereum',
    code: 'ETH',
    icon: `${path}/eth.png`,
    native: 'ETH',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    network: {
      chainId: 1,
      displayName: '',
      rpcCollections: ['https://rpc.ankr.com/eth'],
      blockExplorerUrls: ['https://etherscan.io/'],
    },
  },

  BSC: {
    display: 'Binance Smart Chain',
    name: 'BSC',
    code: 'BSC',
    icon: `${path}/bsc.png`,
    native: 'BNB',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    network: {
      // Mainnet
      chainId: 56,
      rpcCollections: [
        'https://bsc-dataseed.binance.org/',
        'https://bsc-dataseed1.defibit.io/',
        'https://bsc-dataseed1.ninicoin.io/',
      ],
      blockExplorerUrls: ['https://bscscan.com/'],
    },
  },

  // FTM: {
  //   display: 'Fantom Opera Mainnet',
  //   name: 'FANTOM',
  //   code: 'FTM',
  //   icon: `${path}/ftm.png`,
  //   native: 'FTM',
  //   nativeCurrency: {
  //     name: 'FTM',
  //     symbol: 'FTM',
  //     decimals: 18,
  //   },
  //   network: {
  //     chainId: 250,
  //     rpcCollections: ['https://rpc.ftm.tools/'],
  //     blockExplorerUrls: ['https://ftmscan.com/'],
  //   },
  // },

  AVAX: {
    display: 'Avalanche',
    name: 'Avax',
    code: 'AVAX',
    icon: `${path}/avax.png`,
    native: 'AVAX',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    network: {
      chainId: 43114,
      rpcCollections: ['https://api.avax.network/ext/bc/C/rpc'],
      blockExplorerUrls: ['https://snowtrace.io/'],
    },
  },
}

export const getNetworkByChainId = (_chainId: number) => {
  return Object.values(ListNetwork).find((item) => item.network.chainId === _chainId && item)
}
