import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const StyledContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.colors.background};

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000000;
`

const loaderKeyframes = keyframes`
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.2,1.2);
  }
  100% {
      ransform: scale(1,1);
  }
`

const StyledWrapImgLogo = styled.img`
  max-width: 200px;
  animation: ${loaderKeyframes} 1s infinite;
`

const LoadingPage = () => {
  const router = useRouter()

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const handleStart = (url) => {
      if (url !== router.asPath) {
        setLoading(true)
      }
    }
    const handleComplete = (url) => {
      if (url === '/') {
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      } else if (url === window.location.pathname) {
        setLoading(false)
      }
    }
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500)

    if (!loading) {
      router.events.on('routeChangeStart', handleStart)
      router.events.on('routeChangeComplete', handleComplete)
      router.events.on('routeChangeError', handleComplete)
    }

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [])

  return (
    <>
      {loading && (
        <StyledContainer>
          <StyledWrapImgLogo src="/logo.png" />
        </StyledContainer>
      )}
    </>
  )
}
export default LoadingPage
