import { getTokenAllowanceBalance } from 'components/CurrencyNetworkInputPanel/infura'
import { TokenForBridge } from 'state/types'
import { ListNetwork, NetworkEnum } from 'views/Bridge/networks'
import lodash from 'lodash'
import { CurrencyToken, NetworkTypes, TypeToken } from './types'

export const forkjoinRequest = async (promises: Promise<any>[]): Promise<any[]> => {
  const result = []

  for (let index = 0; index < promises.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    const request = await promises[index]
    result.push(request)
  }

  return result
}

interface KeyToken {
  [key: string]: CurrencyToken
}

export const TokenNetwork: KeyToken = {
  ETH: {
    name: 'ETH',
    symbol: 'ETH',
    code: 'ETH',
    icon: '/images/tokens/ETH.png',
    decimals: 18,
    type: TypeToken.NATIVE,
    networks: {
      [NetworkEnum.ETH]: {
        name: 'wETH',
        address: '0x8965349fb649a33a30cbfda057d8ec2c48abe2a2',
      },
      [NetworkEnum.BSC]: {
        type: TypeToken.STABLE,
        address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      },
      [NetworkEnum.FTM]: {
        type: TypeToken.STABLE,
        address: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
      },
      [NetworkEnum.AVAX]: {
        type: TypeToken.STABLE,
        address: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
      },
    },
  },

  BTC: {
    name: 'BTC',
    symbol: 'BTC',
    code: 'BTC',
    icon: '/images/tokens/BTC.png',
    decimals: 18,
    type: TypeToken.STABLE,
    networks: {
      [NetworkEnum.ETH]: {
        address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      },
      [NetworkEnum.BSC]: {
        address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      },
      [NetworkEnum.FTM]: {
        address: '0x321162cd933e2be498cd2267a90534a804051b11',
      },
      [NetworkEnum.AVAX]: {
        address: '0x50b7545627a5162f82a992c33b87adc75187b218',
      },
    },
  },

  BNB: {
    name: 'BNB',
    symbol: 'BNB',
    code: 'BNB',
    icon: '/images/tokens/BNB.png',
    decimals: 18,
    type: TypeToken.NATIVE,
    networks: {
      [NetworkEnum.BSC]: {
        address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      },
      [NetworkEnum.FTM]: {
        address: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',
      },

      [NetworkEnum.AVAX]: {
        address: '0x264c1383ea520f73dd837f915ef3a732e204a493',
      },
    },
  },
  FTM: {
    name: 'FTM',
    symbol: 'FTM',
    code: 'FTM',
    icon: '/images/tokens/FTM.png',
    decimals: 18,
    type: TypeToken.NATIVE,
    networks: {
      [NetworkEnum.ETH]: {
        address: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
      },

      [NetworkEnum.BSC]: {
        address: '0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
      },

      [NetworkEnum.FTM]: {
        address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
      },
    },
  },
  AVAX: {
    name: 'AVAX',
    symbol: 'AVAX',
    code: 'AVAX',
    icon: '/images/tokens/AVAX.png',
    decimals: 18,
    type: TypeToken.NATIVE,
    networks: {
      [NetworkEnum.AVAX]: {
        address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
      },
      [NetworkEnum.ETH]: {
        address: '0x8965349fb649a33a30cbfda057d8ec2c48abe2a2',
      },
      [NetworkEnum.BSC]: {
        address: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
      },
      [NetworkEnum.FTM]: {
        address: '0x511d35c52a3c244e7b8bd92c0c297755fbd89212',
      },
    },
  },

  BUSD: {
    name: 'BUSD',
    symbol: 'BUSD',
    code: 'BUSD',
    icon: '/images/tokens/BUSD.png',
    decimals: 18,
    type: TypeToken.NATIVE,
    networks: {
      [NetworkEnum.AURORA]: {
        address: '0x5d9ab5522c64e1f6ef5e3627eccc093f56167818',
      },
      [NetworkEnum.BSC]: {
        address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      },
      [NetworkEnum.FTM]: {
        address: '0xc931f61b1534eb21d8c11b24f3f5ab2471d4ab50',
      },
    },
  },

  USDC: {
    name: 'USDC',
    symbol: 'USDC',
    code: 'USDC',
    icon: '/images/tokens/USDC.png',
    decimals: 18,
    type: TypeToken.STABLE,
    networks: {
      [NetworkEnum.AVAX]: {
        address: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
      },
      [NetworkEnum.ETH]: {
        address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      },
      [NetworkEnum.BSC]: {
        address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      },
      [NetworkEnum.FTM]: {
        address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      },
    },
  },

  USDT: {
    name: 'USDT',
    symbol: 'USDT',
    code: 'USDT',
    icon: '/images/tokens/USDT.png',
    decimals: 18,
    type: TypeToken.STABLE,
    networks: {
      [NetworkEnum.AVAX]: {
        address: '0xc7198437980c041c805a1edcba50c1ce5db95118',
      },

      [NetworkEnum.ETH]: {
        address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      },

      [NetworkEnum.BSC]: {
        address: '0x55d398326f99059ff775485246999027b3197955',
      },
      [NetworkEnum.FTM]: {
        name: 'fUSDT',
        address: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
      },
    },
  },
}
export const tokenNeedToAddOrUpdate = [
  {
    keyApi: 'bnbv5',
    from: {
      type: TypeToken.NATIVE,
      address: TokenNetwork.BNB.networks[NetworkEnum.AVAX].address,
      chainId: ListNetwork.AVAX.network.chainId,
      variables: {},
    },
    to: {
      type: TypeToken.NATIVE,
      address: TokenNetwork.BNB.networks[NetworkEnum.BSC].address,
      chainId: ListNetwork.BSC.network.chainId,
      variables: {
        SwapFeeRatePerMillion: 0,
        MinimumSwapFee: 0,
        MaximumSwapFee: 0,
      },
    },
  },
]
const getTokenByNetwork = (_network: any) => {
  const currencies = {}

  Object.values(TokenNetwork).forEach((item) => {
    if (Object.keys(item.networks).includes(_network.toString()) && !item.options?.disableFrom?.includes(_network)) {
      currencies[item.code] = item
    }
  })
  return currencies
}

export const getTokensBetweenTwoNetwork = ({ from, to }: { from: NetworkTypes; to: NetworkTypes }) => {
  const _from = getTokenByNetwork(from.network.chainId) || {}
  const _to = getTokenByNetwork(to.network.chainId) || {}

  const commonToken = Object.keys(_from)
    .map((key) => _to[key] && _from[key])
    .filter((item) => item)

  return commonToken
}

export const getInfoTokenToBridge = (_from, _to, infoBridge, _currency): TokenForBridge => {
  try {
    const typeTokenOption = _currency.networks[_from?.network.chainId].type
    const addressTokenAtNetworkFrom = _currency.networks[_from?.network.chainId].address?.toLowerCase() || ''
    const typeToken = typeTokenOption || _currency.type

    let infoNative: TokenForBridge = null
    if (typeToken === TypeToken.STABLE) {
      infoNative = { ...lodash.get(infoBridge, `stablev3[${_from.network.chainId}][${addressTokenAtNetworkFrom}]`, {}) }
    } else if (_from.native === _currency.code || typeToken === TypeToken.NATIVE) {
      infoNative = { ...lodash.get(infoBridge, `native[${_from.network.chainId}][${addressTokenAtNetworkFrom}]`, {}) }
    } else {
      infoNative = {
        ...lodash.get(infoBridge, `underlyingv2[${_from.network.chainId}][${addressTokenAtNetworkFrom}]`, {}),
      }
    }
    if (!infoNative?.destChains) return null
    const destChain = infoNative.destChains[_to?.network?.chainId]
    if (!destChain) return null

    infoNative.infoBridge = destChain
    return infoNative
  } catch (err) {
    return null
  }
}

export const fetchAllowanceBalanceMultipleToken = async ({
  from,
  to,
  account,
  infoBridge,
}: {
  from: NetworkTypes
  to: NetworkTypes
  account: string
  infoBridge?: any
  // eslint-disable-next-line consistent-return
}) =>
  new Promise((resolve, reject) => {
    const commonToken = getTokensBetweenTwoNetwork({ from, to })
    const { chainId } = from.network

    Promise.all(
      commonToken.map(async (item) => {
        const infoCurrency = getInfoTokenToBridge(from, to, infoBridge, item)
        const spender = infoCurrency?.router || infoCurrency?.infoBridge?.options?.router || ''
        const currency = await getTokenAllowanceBalance({ currency: item, account, chainId, spender })
        return currency
      }),
    )
      .then((res) => {
        if (res.length === 0) return

        resolve({
          from,
          to,
          currencies: res,
        })
      })
      .catch(() => {
        resolve({
          from,
          to,
          currencies: [],
        })
      })
  })
